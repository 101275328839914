@import '~antd/dist/antd.less';
@import '~tailwindcss/base';
@import '~tailwindcss/components';
@import '~tailwindcss/utilities';

@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap');
@primary-color: #0089ff; // primary color for all components
@link-color: #0089ff; // link color
@success-color: #52c41a; // success state color
@warning-color: #faad14; // warning state color
@error-color: #f5222d; // error state color
@font-size-base: 14px; // major text font size
@heading-color: #687992; // heading text color
@text-color: #687992; // major text color
@text-color-secondary: rgba(0, 0, 0, 0.45); // secondary text color
@disabled-color: rgba(0, 0, 0, 0.25); // disable state color
@border-radius-base: 2px; // major border radius
@border-color-base: #e5efff; // major border color
@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
	0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05); // major shadow for layers

//Customize Ant Design Theme Here
.ant-page-header {
	padding: 14px 0px;
	.ant-page-header-heading-title {
		font-size: 16px;
	}
	.ant-page-header-heading-left {
		display: block;
	}
	.ant-page-header-heading-sub-title {
		display: block;
		font-size: 14px;
	}
}
.ant-table-wrapper {
	background: #fff;
}
h5.ant-typography {
	font-size: 14px;
}

::-webkit-scrollbar {
	width: 4px;
}

::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px rgba(128, 128, 128, 0);
	border-radius: 4px;
}

::-webkit-scrollbar-thumb {
	background: #68799231;
	border-radius: 4px;
}
::-webkit-scrollbar-thumb:hover {
	background: #687992;
}

body {
	background: #f6f9ff;
	// font-family: "Lato", sans-serif;
	font-family: 'Roboto', sans-serif;
}
